export const OFFER_LIST_REQUEST = "OFFER_LIST_REQUEST";
export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAILURE = "OFFER_LIST_FAILURE";

export const DROPDOWN_DATA_REQUEST = "DROPDOWN_DATA_REQUEST";
export const DROPDOWN_DATA_SUCCESS = "DROPDOWN_DATA_SUCCESS";
export const DROPDOWN_DATA_FAILURE = "DROPDOWN_DATA_FAILURE";

export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILURE = "CREATE_OFFER_FAILURE";
export const CREATE_OFFER_CLEAR = "CREATE_OFFER_CLEAR";
export const CLEAR_OFFER_SUCCESS = "CLEAR_OFFER_SUCCESS";


export const EDIT_OFFER_REQUEST = "EDIT_OFFER_REQUEST";
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS";
export const EDIT_OFFER_FAILURE = "EDIT_OFFER_FAILURE";
export const UPDATE_OFFER_CLEAR = "UPDATE_OFFER_CLEAR";
// Disable Offer
export const DISABLE_OFFER_REQUEST = " DISABLE_OFFER_REQUEST";
export const DISABLE_OFFER_SUCCESS = "DISABLE_OFFER_SUCCESS";
export const DISABLE_OFFER_FAILED = "DISABLE_OFFER_FAILED";
export const RESET_DISABLE_DATA = "RESET_DISABLE_DATA";


// Delete Offer
export const DELETE_OFFER_REQUEST = " DELETE_OFFER_REQUEST";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILED = "DELETE_OFFER_FAILED";
export const RESET_DELETE_DATA = "RESET_DELETE_DATA";

export const SET_OFFER_STATUS = "SET_OFFER_STATUS";
