// Get Menu Category
export const GET_MENU_CATEGORY_REQUEST = "GET_MENU_CATEGORY_REQUEST";
export const GET_MENU_CATEGORY_SUCCESS = "GET_MENU_CATEGORY_SUCCESS";
export const GET_MENU_CATEGORY_FAILED = "GET_MENU_CATEGORY_FAILED";

// Get menu Sub Category
export const GET_MENU_SUB_CATEGORY_REQUEST = "GET_MENU_SUB_CATEGORY_REQUEST";
export const GET_MENU_SUB_CATEGORY_SUCCESS = "GET_MENU_SUB_CATEGORY_SUCCESS";
export const GET_MENU_SUB_CATEGORY_FAILED = "GET_MENU_SUB_CATEGORY_FAILED";

// Get TAG Class
export const GET_TAG_CLASS_REQUEST = "GET_TAG_CLASS_REQUEST";
export const GET_TAG_CLASS_SUCCESS = "GET_TAG_CLASS_SUCCESS";
export const GET_TAG_CLASS_FAILED = "GET_TAG_CLASS_FAILED";

// Get Availability Class
export const GET_AVAILABILITY_REQUEST = "GET_AVAILABILITY_REQUEST";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_FAILED = "GET_AVAILABILITY_FAILED";

// Get Ingredients
export const GET_INGR_REQUEST = "GET_INGR_REQUEST";
export const GET_INGR_SUCCESS = "GET_INGR_SUCCESS";
export const GET_INGR_FAILED = "GET_INGR_FAILED";

// Get Modifiers
export const GET_MODIFIER_REQUEST = "GET_MODIFIER_REQUEST";
export const GET_MODIFIER_SUCCESS = "GET_MODIFIER_SUCCESS";
export const GET_MODIFIER_FAILED = "GET_MODIFIER_FAILED";

// Add Menu Items
export const ADD_MENU_ITEM_REQUEST = "ADD_MENU_ITEM_REQUEST";
export const ADD_MENU_ITEM_SUCCESS = "ADD_MENU_ITEM_SUCCESS";
export const ADD_MENU_ITEM_FAILED = "ADD_MENU_ITEM_FAILED";
export const CLEAR_MENU_ITEM_SUCCESS = "CLEAR_MENU_ITEM_SUCCESS";

// Update Menu Item
export const UPDATE_MENU_ITEM_REQUEST = "UPDATE_MENU_ITEM_REQUEST";
export const UPDATE_MENU_ITEM_SUCCESS = "UPDATE_MENU_ITEM_SUCCESS";
export const UPDATE_MENU_ITEM_FAILED = "UPDATE_MENU_ITEM_FAILED";
export const CLEAR_MENU_ITEM_MSG = "CLEAR_MENU_ITEM_MSG";

// Delete Menu Item
export const DELETE_MENU_ITEM_REQUEST = "DELETE_MENU_ITEM_REQUEST";
export const DELETE_MENU_ITEM_SUCCESS = "DELETE_MENU_ITEM_SUCCESS";
export const DELETE_MENU_ITEM_FAILED = "DELETE_MENU_ITEM_FAILED";
export const RESET_DELETE_DATA = "RESET_DELETE_DATA";

// update menu item Attributes
export const UPDATE_MENU_ATTRIBUTE_REQUEST = "UPDATE_MENU_ATTRIBUTE_REQUEST";
export const UPDATE_MENU_ATTRIBUTE_SUCCESS = "UPDATE_MENU_ATTRIBUTE_SUCCESS";
export const UPDATE_MENU_ATTRIBUTE_FAILED = "UPDATE_MENU_ATTRIBUTE_FAILED";
