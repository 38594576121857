export const UUID = "UUID";
export const CREDENTIALS = "CREDENTIALS";
export const STORAGE_BUCKET_URL =
  "https://static.magilhub.com/";

export const SELECTED_BRANCH_DATA = "SELECTED_BRANCH_DATA";

export const SUBSCRIPTION_STATUS = {
  ACTIVATED: "SUB_1",
  AUTHENTICATED: "SUB_1",
  CHARGED: "SUB_2",
  CANCELLED: "SUB_4",
  COMPLETED: "SUB_5",
  HALTED: "SUB_3",
  PENDING: "SUB_3",
};

export const RAZORPAY_SUBSCRIPTION_STATUS = {
  CREATED: "created",
  AUTHENTICATED: "authenticated",
  ACTIVE: "active",
  PENDING: "pending",
  HALTED: "halted",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
  EXPIRED: "expired",
};

export const ACTIVE_SUBSCRIPTION_STATUS = [
  SUBSCRIPTION_STATUS.ACTIVATED,
  SUBSCRIPTION_STATUS.AUTHENTICATED,
  SUBSCRIPTION_STATUS.HALTED,
  SUBSCRIPTION_STATUS.PENDING,

  RAZORPAY_SUBSCRIPTION_STATUS.ACTIVE,
  RAZORPAY_SUBSCRIPTION_STATUS.CREATED,
  RAZORPAY_SUBSCRIPTION_STATUS.PENDING,
  RAZORPAY_SUBSCRIPTION_STATUS.HALTED,
];

export const INACTIVE_SUBSCRIPTION_STATUS = [
  SUBSCRIPTION_STATUS.CANCELLED,
  SUBSCRIPTION_STATUS.COMPLETED,
];

export const IS_SPORT_VERTICAL = "Sports";
export const IS_SPORT_DOMAIN = "SPORTS";
