export const convertKeysToCamelCase = (obj) => {
    const newObj = {};

    Object.keys(obj).forEach((key) => {
        // Convert snake_case to camelCase
        const camelCaseKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());

        // Add the new key-value pair to the new object
        newObj[camelCaseKey] = obj[key];
    });

    return newObj;
};

export const normalizeArrayOfObjects = (array) => {
    return array.map((obj) => convertKeysToCamelCase(obj));
};
