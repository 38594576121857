// Get Locations of a Merchant
export const OUTLET_REQUEST = "OUTLET_REQUEST";
export const OUTLET_SUCCESS = "OUTLET_SUCCESS";
export const OUTLET_FAILURE = "OUTLET_FAILURE";

// Add Employee
export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE";
export const ADD_EMPLOYEE_RESET = "ADD_EMPLOYEE_RESET";

// Delete Employee
export const REMOVE_EMPLOYEE_REQUEST = "REMOVE_EMPLOYEE_REQUEST";
export const REMOVE_EMPLOYEE_SUCCESS = "REMOVE_EMPLOYEE_SUCCESS";
export const REMOVE_EMPLOYEE_FAILURE = "REMOVE_EMPLOYEE_FAILURE";
export const RESET_REMOVE_EMPLOYEE_DATA = "RESET_REMOVE_EMPLOYEE_DATA";

// View Employees
export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAILURE = "GET_EMPLOYEE_FAILURE";
export const SET_EMPLOYEE_DETAILS_LOADING = "SET_EMPLOYEE_DETAILS_LOADING";

// edit Employees
export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";
export const EDIT_EMPLOYEE_DATA = "EDIT_EMPLOYEE_DATA";
export const CLEAR_EDIT_EMPLOYEE_DATA = "CLEAR_EDIT_EMPLOYEE_DATA";


// Get All Menu Details
export const GET_MENUS_REQUEST = "GET_MENUS_REQUEST";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_ERROR = "GET_MENUS_ERROR";
export const GET_MENUS_FAILURE = "GET_MENUS_FAILURE";
export const CLEAR_MENU_DATA = "CLEAR_MENU_DATA";

// Manage user access
export const USER_ACCESS_EMPLOYEE_REQUEST = "USER_ACCESS_EMPLOYEE_REQUEST";
export const USER_ACCESS_EMPLOYEE_SUCCESS = "USER_ACCESS_EMPLOYEE_SUCCESS";
export const USER_ACCESS_EMPLOYEE_FAILURE = "USER_ACCESS_EMPLOYEE_FAILURE";
export const USER_ACCESS_EMPLOYEE_CLEAR = "USER_ACCESS_EMPLOYEE_CLEAR";

// Update Employee PIN
export const UPDATE_EMPLOYEE_PIN_REQUEST = "UPDATE_EMPLOYEE_PIN_REQUEST";
export const UPDATE_EMPLOYEE_PIN_SUCCESS = "UPDATE_EMPLOYEE_PIN_SUCCESS";
export const UPDATE_EMPLOYEE_PIN_FAILED = "UPDATE_EMPLOYEE_PIN_FAILED";
export const UPDATE_EMPLOYEE_PIN_CLEAR = "UPDATE_EMPLOYEE_PIN_CLEAR";

//Get Employee By ID
export const GET_EMPLOYEE_BY_ID_REQUEST = 'GET_EMPLOYEE_BY_ID_REQUEST';
export const GET_EMPLOYEE_BY_ID_SUCCESS = 'GET_EMPLOYEE_BY_ID_SUCCESS';
export const GET_EMPLOYEE_BY_ID_FAILURE = 'GET_EMPLOYEE_BY_ID_FAILURE';

//Get Employee By ID
export const GET_EMPLOYEE_ROLE_BY_ID_REQUEST = 'GET_EMPLOYEE_ROLE_BY_ID_REQUEST';
export const GET_EMPLOYEE_ROLE_BY_ID_SUCCESS = 'GET_EMPLOYEE_ROLE_BY_ID_SUCCESS';
export const GET_EMPLOYEE_ROLE_BY_ID_FAILURE = 'GET_EMPLOYEE_ROLE_BY_ID_FAILURE';

//Roles
export const ROLES_REQUEST = "ROLES_REQUEST"
export const ROLES_SUCCESS = "ROLES_SUCCESS"
export const ROLES_FAILURE = "ROLES_FAILURE"

//Block/Unblock Employee
export const EMPLOYEE_STATUS_REQUEST = "EMPLOYEE_STATUS_REQUEST"
export const EMPLOYEE_STATUS_SUCCESS = "EMPLOYEE_STATUS_SUCCESS"
export const EMPLOYEE_STATUS_FAILURE = "EMPLOYEE_STATUS_FAILURE"

export const RESET_EMPLOYEE_ACTION_COMPLETED = "RESET_EMPLOYEE_ACTION_COMPLETED"

//pin refreshment
export const REFRESH_PIN_REQUEST = "REFRESH_PIN_REQUEST"
export const REFRESH_PIN_SUCCESS = "REFRESH_PIN_SUCCESS"
export const REFRESH_PIN_FAILURE = "REFRESH_PIN_FAILURE"
