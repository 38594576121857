import { produce } from "immer";
import {
  GET_MENU_CATEGORY_REQUEST,
  GET_MENU_CATEGORY_SUCCESS,
  GET_MENU_CATEGORY_FAILED,
  GET_MENU_SUB_CATEGORY_REQUEST,
  GET_MENU_SUB_CATEGORY_SUCCESS,
  GET_MENU_SUB_CATEGORY_FAILED,
  GET_TAG_CLASS_FAILED,
  GET_TAG_CLASS_SUCCESS,
  GET_TAG_CLASS_REQUEST,
  GET_INGR_REQUEST,
  GET_INGR_SUCCESS,
  GET_INGR_FAILED,
  ADD_MENU_ITEM_SUCCESS,
  ADD_MENU_ITEM_REQUEST,
  ADD_MENU_ITEM_FAILED,
  UPDATE_MENU_ITEM_REQUEST,
  UPDATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_FAILED,
  DELETE_MENU_ITEM_REQUEST,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILED,
  GET_MODIFIER_REQUEST,
  GET_MODIFIER_SUCCESS,
  GET_MODIFIER_FAILED,
  GET_AVAILABILITY_REQUEST,
  GET_AVAILABILITY_SUCCESS,
  GET_AVAILABILITY_FAILED,
  UPDATE_MENU_ATTRIBUTE_REQUEST,
  UPDATE_MENU_ATTRIBUTE_SUCCESS,
  UPDATE_MENU_ATTRIBUTE_FAILED,
  CLEAR_MENU_ITEM_SUCCESS,
  CLEAR_MENU_ITEM_MSG,
  RESET_DELETE_DATA,
} from "../constants/productCatalogConstants";

const initialProductCatalogState = {
  categoryData: [],
  getCategoryLoading: false,
  getCategorySuccess: false,
  subCategoryData: [],
  getSubCategoryLoading: false,
  getSubCategorySuccess: false,
  taxClass: [],
  getTaxClassLoading: false,
  getTaxClassSuccess: false,
  ingredients: [],
  getIngredientsLoading: false,
  getIngredientsSuccess: false,
  modifier: [],
  getModifierLoading: false,
  getModifierSuccess: false,

  addMenuLoading: false,
  addMenuSuccess: false,
  addMenuFailed: false,
  addMenuSuccessMessage: "",
  addMenuFailedMessage: "",
  updateMenuItemLoading: false,
  updateMenuItemSuccess: false,
  updateMenuItemFailed: false,
  updateMenuItemSuccessMessage: "",
  updateMenuItemFailureMessage: "",
  deleteMenuItemLoading: false,
  deleteMenuItemSuccess: false,
  deleteMenuItemFailed: false,
  deleteMenuItemSuccessMessage: "",
  deleteMenuItemFailureMessage: "",
  availability: [],
  getAvailabilityLoading: false,
  getAvailabilitySuccess: false,
  updateMenuAttributeLoading: false,
  updateMenuAttributeSuccess: "",
  updateMenuAttributeFailed: false,
};

export default function employeeReducer(
  state = initialProductCatalogState,
  action
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Get Menu Category
      case GET_MENU_CATEGORY_REQUEST:
        draft.categoryData = [];
        draft.getCategoryLoading = true;
        draft.getCategorySuccess = false;
        break;
      case GET_MENU_CATEGORY_SUCCESS:
        draft.categoryData = action.payload;
        draft.getCategoryLoading = false;
        draft.getCategorySuccess = true;
        break;
      case GET_MENU_CATEGORY_FAILED:
        draft.categoryData = [];
        draft.getCategoryLoading = false;
        draft.getCategorySuccess = false;
        break;
      // Get menu Sub Category
      case GET_MENU_SUB_CATEGORY_REQUEST:
        draft.subCategoryData = [];
        draft.getSubCategoryLoading = true;
        draft.getSubCategorySuccess = false;
        break;
      case GET_MENU_SUB_CATEGORY_SUCCESS:
        draft.subCategoryData = action.payload;
        draft.getSubCategoryLoading = false;
        draft.getSubCategorySuccess = true;
        break;
      case GET_MENU_SUB_CATEGORY_FAILED:
        draft.subCategoryData = [];
        draft.getCategoryLoading = false;
        draft.getCategorySuccess = false;
        break;
      // Get Tax Class
      case GET_TAG_CLASS_REQUEST:
        draft.tagClass = [];
        draft.getTagClassLoading = true;
        draft.getTagClassSuccess = false;
        break;
      case GET_TAG_CLASS_SUCCESS:
        draft.tagClass = action.payload;
        draft.getTagClassLoading = false;
        draft.getTagClassSuccess = true;
        break;
      case GET_TAG_CLASS_FAILED:
        draft.tagClass = [];
        draft.getTagClassLoading = false;
        draft.getTagClassSuccess = false;
        break;
      // Get Ingredients
      case GET_INGR_REQUEST:
        draft.ingredients = [];
        draft.getSubCategoryLoading = true;
        draft.getIngredientsSuccess = false;
        break;
      case GET_INGR_SUCCESS:
        draft.ingredients = action.payload;
        draft.getSubCategoryLoading = false;
        draft.getIngredientsSuccess = true;
        break;
      case GET_INGR_FAILED:
        draft.ingredients = [];
        draft.getSubCategoryLoading = false;
        draft.getIngredientsSuccess = false;
        break;
      // Get Modifier
      case GET_MODIFIER_REQUEST:
        draft.getModifierLoading = true;
        draft.getModifierSuccess = false;
        draft.modifier = [];
        break;
      case GET_MODIFIER_SUCCESS:
        draft.getModifierLoading = false;
        draft.getModifierSuccess = true;
        draft.modifier = action.payload;
        break;
      case GET_MODIFIER_FAILED:
        draft.getModifierLoading = false;
        draft.getModifierSuccess = false;
        draft.modifier = [];
        break;
      // Get Availability
      case GET_AVAILABILITY_REQUEST:
        draft.getAvailabilityLoading = true;
        draft.getAvailabilitySuccess = false;
        draft.availability = [];
        break;
      case GET_AVAILABILITY_SUCCESS:
        draft.getAvailabilityLoading = false;
        draft.getAvailabilitySuccess = true;
        draft.availability = action.payload;
        break;
      case GET_AVAILABILITY_FAILED:
        draft.getAvailabilityLoading = false;
        draft.getAvailabilitySuccess = false;
        draft.availability = [];
        break;
      // Add Menu Item
      case ADD_MENU_ITEM_REQUEST:
        draft.addMenuLoading = true;
        draft.addMenuFailed = false;
        draft.addMenuSuccess = false;
        draft.addMenuSuccessMessage = "";
        draft.addMenuFailedMessage = "";
        break;
      case ADD_MENU_ITEM_SUCCESS:
        draft.addMenuLoading = false;
        draft.addMenuFailed = false;
        draft.addMenuSuccess = true;
        draft.addMenuSuccessMessage = action.payload;
        draft.addMenuFailedMessage = "";
        break;
      case ADD_MENU_ITEM_FAILED:
        draft.addMenuLoading = false;
        draft.addMenuFailed = true;
        draft.addMenuSuccess = false;
        draft.addMenuSuccessMessage = "";
        draft.addMenuFailedMessage = action.payload;
        break;
      // Update Menu Item
      case UPDATE_MENU_ITEM_REQUEST:
        draft.updateMenuItemLoading = true;
        draft.updateMenuItemFailed = false;
        draft.updateMenuItemSuccess = false;
        draft.updateMenuItemFailureMessage = "";
        draft.updateMenuItemSuccessMessage = "";
        break;
      case UPDATE_MENU_ITEM_SUCCESS:
        draft.updateMenuItemLoading = false;
        draft.updateMenuItemFailed = false;
        draft.updateMenuItemSuccess = true;
        draft.updateMenuItemFailureMessage = "";
        draft.updateMenuItemSuccessMessage = action.payload;
        break;
      case UPDATE_MENU_ITEM_FAILED:
        draft.updateMenuItemLoading = false;
        draft.updateMenuItemFailed = true;
        draft.updateMenuItemSuccess = false;
        draft.updateMenuItemFailureMessage = action.payload;
        draft.updateMenuItemSuccessMessage = "";
        break;
      // Delete Menu Item
      case DELETE_MENU_ITEM_REQUEST:
        draft.deleteMenuItemLoading = true;
        draft.deleteMenuItemFailed = false;
        draft.deleteMenuItemSuccess = false;
        draft.deleteMenuItemFailureMessage = "";
        draft.deleteMenuItemSuccessMessage = "";
        break;
      case DELETE_MENU_ITEM_SUCCESS:
        draft.deleteMenuItemLoading = false;
        draft.deleteMenuItemFailed = false;
        draft.deleteMenuItemSuccess = true;
        draft.deleteMenuItemFailureMessage = "";
        draft.deleteMenuItemSuccessMessage = action.payload;
        break;
      case DELETE_MENU_ITEM_FAILED:
        draft.deleteMenuItemLoading = false;
        draft.deleteMenuItemFailed = true;
        draft.deleteMenuItemSuccess = false;
        draft.deleteMenuItemFailureMessage = action.payload;
        draft.deleteMenuItemSuccessMessage = "";
        break;
      case RESET_DELETE_DATA:
        draft.deleteMenuItemLoading = false;
        draft.deleteMenuItemFailed = false;
        draft.deleteMenuItemSuccess = false;
        draft.deleteMenuItemFailureMessage = "";
        draft.deleteMenuItemSuccessMessage = "";
        break;
      // Update Menu Attribute
      case UPDATE_MENU_ATTRIBUTE_REQUEST:
        draft.updateMenuAttributeLoading = true;
        draft.updateMenuAttributeSuccess = [];
        draft.updateMenuAttributeFailed = false;
        break;
      case UPDATE_MENU_ATTRIBUTE_SUCCESS:
        draft.updateMenuAttributeLoading = false;
        draft.updateMenuAttributeSuccess = action.payload;
        draft.updateMenuAttributeFailed = false;
        break;
      case UPDATE_MENU_ATTRIBUTE_FAILED:
        draft.updateMenuAttributeLoading = false;
        draft.updateMenuAttributeSuccess = [];
        draft.updateMenuAttributeFailed = true;
        break;
      case CLEAR_MENU_ITEM_SUCCESS:
        draft.addMenuSuccessMessage = "";
        draft.updateMenuItemSuccessMessage = "";
        break;
      case CLEAR_MENU_ITEM_MSG:
        draft.updateMenuAttributeSuccess = "";
        break;
      default:
        break;
    }
  });
}
