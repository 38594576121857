export const CREDENTIALS_STORE = "CREDENTIALS_STORE";
export const CREDENTIALS_UPDATE = "CREDENTIALS_UPDATE";
export const CREDENTIALS_CLEAR = "CREDENTIALS_CLEAR";

export const SIGNOUT = "SIGNOUT";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_RESET = "SIGNUP_RESET";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const CLEAR_SIGNIN = "CLEAR_SIGNIN";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const OTP_STATE_CLEAR = "OTP_STATE_CLEAR";
export const OTP_VERIFICATION_REQUEST = "OTP_VERIFICATION_REQUEST";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE";

// Get Restaurant Details
export const RESTAURANT_DETAIL_REQUEST = "RESTAURANT_DETAIL_REQUEST";
export const RESTAURANT_DETAIL_SUCCESS = "RESTAURANT_DETAIL_SUCCESS";
export const RESTAURANT_DETAIL_FAILURE = "RESTAURANT_DETAIL_FAILURE";

// Selected Branch
export const SELECTED_BRANCH = "SELECTED_BRANCH";
