export const GET_SUBSCRIPTION_PLAN_REQUEST = "GET_SUBSCRIPTION_PLAN_REQUEST";
export const GET_SUBSCRIPTION_PLAN_SUCCESS = "GET_SUBSCRIPTION_PLAN_SUCCESS";
export const GET_SUBSCRIPTION_PLAN_FAILURE = "GET_SUBSCRIPTION_PLAN_FAILURE";

// Create Subscription
export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";
export const CLEAR_SUBSCRIPTION_REQUEST = "CLEAR_SUBSCRIPTION_REQUEST";

// Get Billing Details
export const SUBSCRIPTION_BILLING_REQUEST = "SUBSCRIPTION_BILLING_REQUEST";
export const SUBSCRIPTION_BILLING_REQUEST_SUCCESS = "SUBSCRIPTION_BILLING_REQUEST_SUCCESS";
export const SUBSCRIPTION_BILLING_REQUEST_FAILURE = "SUBSCRIPTION_BILLING_REQUEST_FAILURE";

// Update Subscription
export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";
export const CLEAR_UPDATE_SUBSCRIPTION = "CLEAR_UPDATE_SUBSCRIPTION";

// Cancel Subscription
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";